import React from 'react'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'

import neonLogo from './neon-app-logo.png'
import AppleStore from './apple-store.svg'
import GoogleStore from './google-store.svg'

const APPLE_DOWNLOAD_URL =
  'https://apps.apple.com/nz/app/lightbox-nz/id893849629'
const GOOGLE_DOWNLOAD_URL =
  'https://play.google.com/store/apps/details?id=nz.co.lightbox'

const styles = {
  container: {
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.6
  },
  logo: {
    height: 100,
    width: 100,
    borderRadius: 15
  },
  downloadsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  download: {
    height: 50,
    border: '1px solid #fff',
    margin: 5,
    borderRadius: 5,
    '& svg': {
      height: 50,
      fill: '#fff'
    }
  }
}

const AppDownload = ({ classes }) => {
  const brand = 'Neon'

  return (
    <div className={classes.container}>
      <img src={neonLogo} alt={brand} className={classes.logo} />

      <p className={classes.text}>
        Download our
        {' '}
        {brand}
        {' '}
        app now to watch
      </p>

      <div className={classes.downloadsContainer}>
        <a href={APPLE_DOWNLOAD_URL} className={classes.download}>
          <ReactSVG src={AppleStore} />
        </a>
        <a href={GOOGLE_DOWNLOAD_URL} className={classes.download}>
          <ReactSVG src={GoogleStore} />
        </a>
      </div>
    </div>
  )
}

export default injectSheet(styles)(AppDownload)
